<script setup>
import { computed, watch, ref } from "vue"
import { useStore } from "vuex"
import { useRoute } from "vue-router"
import { getUserDisplayName } from "@common/js/helpers"
import Snackbar from "@/js/components/Snackbar.vue"
import CookieConsentDialog from "@/js/components/CookieConsentDialog.vue"

const store = useStore()
const route = useRoute()
const isIframe = ref(route.query.iframe !== "true")
const intercomAppId = import.meta.env.VITE_INTERCOM_APP_ID
const intercomEnabled = import.meta.env.VITE_INTERCOM_ENABLED === "true"

const user = computed(() => store.state.auth.user)
const userType = computed(() => store.state.auth.type)

const getIntercomName = (user) => {
  if (user.valueType === "installer") return user.companyName
  else return getUserDisplayName(user)
}

const bootIntercom = () => {
  window.Intercom("boot", {
    app_id: intercomAppId,
    user_id: user.value ? user.value.id : null,
    name: user.value ? getIntercomName(user.value) : null,
    email: user.value ? user.value.email : null,
  })
}

watch(
  () => user.value,
  (val) => {
    if (intercomEnabled && !route.query.iframe)
      window.Intercom("update", { email: val ? val.email : null })
  },
  { immediate: true }
)

watch(
  () => route.query, // Watch the route's path
  (val) => {
    if (intercomEnabled && route.query.iframe !== "true") bootIntercom()
  },
  {} // Run immediately on component mount
)
</script>

<template>
  <div>
    <Snackbar />
    <router-view></router-view>
    <CookieConsentDialog v-if="!isIframe" />
  </div>
</template>
