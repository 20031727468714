export default {
  "mission-statement":
    "<span class='opacity-80'>Notre mission : </span>orchestrer l’essor de l’énergie solaire <span class='opacity-80'>pour nous tous.</span>",
  intro: {
    title: "Acteur de l’indépendance énergétique de France",
    subtitle: "Faire avancer la transition énergétique, ensemble",
    description:
      "Dès le début, notre mission a été d’orchestrer l’essor de l’énergie solaire à grande échelle pour nous tous. Nous réalisons le projet solaire de France en aidant chaque projet solaire individuel à se réaliser en toute confiance, même pour les particuliers. Nous sommes fiers de pouvoir aider. Car si chaque citoyen réalise l’indépendance énergétique chez soi, alors la France l’aura également réalisé.",
  },
  story: {
    title: "Notre parcours",
    "paragraph-1":
      "Notre technologie a pour vocation de réduire la fracture numérique qui se creuse dans notre pays.",
    "paragraph-2":
      "Notre outil informatique du quotidien aidera bientôt des milliers d’artisans photovoltaïques de proximité à croître leurs activités. Sachez qu’ils forment un tissu économique fragile et dorénavant essentiel à la vitalité des communes rurales. Les installations photovoltaïques génèrent déjà des revenus essentiels à la survie des exploitations agricoles, viticoles et apicoles.",
    "paragraph-3":
      "La filière photovoltaïque est la source d’un véritable gisement d’emplois au sein des territoires ruraux, source de travail technique de haut niveau donc bien payé. L’énergie solaire contribue ainsi à l’essor d’une économie locale, au cœur d’une transition énergétique régénératrice. Ce sont nos territoires appauvris qui retrouvent la prospérité.",
    "paragraph-4":
      "L’indépendance énergétique de la France est possible grâce aux technologies propres ; et nous y sommes à la tête car à la pointe.",
    "paragraph-5":
      "Nous nous sommes élancés dans le solaire dès qu’on a compris à quel point il est difficile à nos parents de devenir autosuffisant en énergie propre, malgré le formidable progrès des technologies photovoltaïques.",
    "paragraph-6":
      "Implantée en Provence, notre jeune entreprise innovante est composée de spécialistes de l’énergie solaire et d'informaticiens. À ce jour, nous n’avons jamais eu besoin de lever de l’argent ni de dépenser un seul euro en publicité. Au contraire, notre méthode c’est la satisfaction client et le bouche-à-oreille.",
    "paragraph-7":
      "Nos parcours à l’étranger, et formations autodidactes en génie électrique, nous permettent aujourd’hui de nous donner les moyens de nos ambitions.",
    "paragraph-8":
      "Notre ambition ? L'énergie solaire partout pour nous tous … et répondre ainsi aux idéaux de justice climatique de l’Accord de Paris.",
  },
  stats: {
    title: "Nous sommes des orchestrateurs de projets solaires",
    description:
      "Notre plateforme communautaire met en lien tous les porteurs de projets solaires avec tous les professionnels du photovoltaïque et du stockage ; ensemble avec tous les acteurs de la transition énergétique - bureaux d’études, fournisseurs, financiers, municipalités, services publics.",
    "flagship-projects": "Chantiers en ligne",
    "flagship-projects-stat": "100+ MW",
    "installers-on-our-platform": "Artisans photovoltaïques",
    "solar-projects-enabled": "Projets solaires propulsés",
    "followers-stat": "25 000+",
    followers: "Personnes nous suivent",
  },
  team: {
    title: "L’équipe derrière la technologie",
    description:
      "Nous sommes passionnées par ce que nous faisons et soucieux d'obtenir les meilleurs résultats pour nos clients.",
    people: {
      "person-1": {
        name: "Georges",
        role: "Responsable relations & cofondateur",
        bio: "Georges Séraphim-André Nevsky aide des milliers nos concitoyens à découvrir leur potentiel solaire. Il aide les artisans photovoltaïques à bénéficier des nouvelles technologies de pointe.",
      },
      "person-2": {
        name: "Yann",
        role: "Responsable opérations & cofondateur",
        bio: "Yann Vermeil Schremmer développe de nouveaux modes de fonctionnement en interne, puis coordonne les divers interlocuteurs externes pour allier entreprenariat et environnement.",
      },
      "person-3": {
        name: "Maarten",
        role: "Responsable technologie & cofondateur",
        bio: "Maarten Elgar développe la technologie qui aide les foyers à se mettre à l'énergie solaire en leur permettant d'obtenir des devis compétitifs, puis de réaliser le reste du parcours en ligne.",
      },
      "person-4": {
        name: "Kelly",
        role: "Développeuse",
        bio: "Kelly Muscat créé les visuels du site internet. Elle compte faire rayonner projetsolaire, qui lui tient a cœur pour sa noble mission : rendre le solaire plus accessible autant pour les professionnels que pour les particuliers !",
      },
      "person-5": {
        name: "Alice",

        role: "Coordinatrice de communauté",

        bio: "Alice Lucà contribue au développement de notre marque, optimise notre présence en ligne et participe à la croissance des communautés liées au photovoltaïque, pour une énergie solaire à portée de tous·tes.",
      },
      "person-6": {
        name: "Wakidou",
        role: "Développeur frontend",
        bio: "Wakidou Abdallah est développeur frontend. Il contribue au développement de la plateforme en créant des interfaces ergonomiques et performantes. Son objectif est de faciliter et d'accélérer le passage au solaire à travers le portail communautaire.",
      },
      "person-7": {
        name: "Louise",
        role: "Coordinatrice d'opérations de croissance",
        bio: "Louise Remy est coordinatrice des opérations de croissance. Elle elle lien entre les installateurs et nos équipes de développement et d’opérations. Son objectif est d’améliorer notre outil au maximum en vue de la pleine satisfaction de nos clients.",
      },
      "person-8": {
        name: "Amal",
        role: "Coordonnatrice des ventes",
        bio: "Amal joue un rôle essentiel dans le suivi des processus de vente, en garantissant une bonne organisation des actions commerciales et en assurant un lien efficace avec les clients pour soutenir les objectifs de l’équipe.",
      },
    },
  },
  career: {
    title: "Notre projetsolaire grandit. Grandissez avec nous.",
    description:
      "Implantée en Provence, nous sommes à la recherche de talents à faire fleurir et prospérer. Envie d’avoir un impact sur le climat en aidant nos concitoyens à faire la revolution solaire ? Bienvenue !",
    openings: {
      title: "Postes actuellement ouverts",
      "fullstack-engineer": "Développeur fullstack",
      "junior-frontend-engineer": "Développeur frontend",
      "junior-account-representative": "Chargé de comptes",
      "account-representative": "Responsable grands comptes",
      "job-openings": "Offres d'emploi",
      role: "Role",
      description: "Description",
      salary: "Salaire",
      location: "Localisation",
      "view-all-openings": "Voir tous les postes vacants",
    },
    process: {
      "step-1": {
        title: "Postuler",
        description: "Partagez-nous votre expérience et savoir faire.",
      },
      "step-2": {
        title: "Vérification",
        description: "Votre candidature est évaluée par rapport aux besoins.",
      },
      "step-3": {
        title: "Entretien",
        description: "Vous prenez connaissance de l’équipe et de ces valeurs.",
      },
      "step-4": {
        title: "Proposition",
        description: "Nous nous engageons auprès de vous.",
      },
    },
    cta: {
      text: "Voulez-vous créer quelque chose de grand ?",
      button: "Rejoignez-nous aujourd’hui",
    },

    commitments: {
      FULL_TIME: "Temps plein",
      PART_TIME: "Temps partiel",
      INTERNSHIP: "Stage",
      CONTRACT: "Auto-entrepreneur",
      COFOUNDER: "Directeur⸱ice",
    },

    roles: {
      COFOUNDER: "Directeur⸱ice",
      PROGRAMMING: "Développeur⸱euse",
      CONTRACT: "Auto-entrepreneur",
      DESIGN: "Designeur⸱euse",
      MANAGEMENT: "Gérant⸱e",
      BUSINESS_DEVELOPMENT: "Développeur⸱euse d'affaires",
      SALES_AND_MARKETING: "Commercial⸱e",
      ADMINISTRATION: "Administratif",
    },

    apply: {
      title: "Nouvelle candidature",
      subtitle:
        "Parlez-nous de vous ! Complétez le formulaire ci-dessous pour soumettre votre candidature.",
    },
  },
}
